import { merge, set } from "lodash";

const YEARS = [
    "static",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025"
]

const SINGLE_YEAR_SHEETS = [{
    sheet: "EPA",
    doc: "epa",
    year: 2023,
    source: "EPA",
}, {
    doc: "exio",
    sheet: "EXIO",
    year: 2022,
    source: "EXIO",
}, {
    sheet: "EXIO Averages",
    doc: "exio-averages",
    year: 2021,
    source: "Zenodo 2021"
}
]

function calculateAverageOfArrayValues(arr) {
    return arr.reduce((acc, val) => acc + val, 0) / arr.length;
}


function setEmissionFactor({ obj, yearsToCheck, sheet }) {

    Object.entries(obj).forEach(([key, child]) => {
        if (!child) return

        const allValuesAreNull = Object.values(child).every(v => v === null)
        if (allValuesAreNull) {
            obj[key] = null
            return
        }

        const childIsIntermediate = Object.values(child).some((v) => !!v && typeof v === "object")
        if (childIsIntermediate) {
            obj[key] = setEmissionFactor({ obj: child, yearsToCheck, sheet })
        } else {
            for (let year of yearsToCheck.map(y => y.toString())) {
                if (child[year] || child[year] === 0) {

                    const newFactor = child[year]
                    obj[key] = newFactor
                    break
                }
            }
        }

    })
    return obj
}


export const getEmissionFactors = (emissionFactors, periodStart, periodEnd) => {
    if (!emissionFactors) return;
    try {

        const startYear = new Date(periodStart).getFullYear();
        const endYear = new Date(periodEnd).getFullYear();

        let targetYear = startYear
        if (startYear !== endYear) {
            const numberOfDaysInFirstYear = new Date(startYear, 11, 31) - new Date(periodStart) + 1
            const numberOfDaysInSecondYear = new Date(periodEnd) - new Date(endYear, 0, 1) + 1

            if (numberOfDaysInFirstYear < numberOfDaysInSecondYear) {
                targetYear = endYear
            }
        }

        // console.log("targetYear", targetYear)

        const multiYearSheets = emissionFactors.filter((doc) => !SINGLE_YEAR_SHEETS.map(({ sheet }) => sheet).includes(doc.sheet))
        const singleYearSheets = emissionFactors.filter((doc) => SINGLE_YEAR_SHEETS.map(({ sheet }) => sheet).includes(doc.sheet))


        const multiYearSheetsConverted = multiYearSheets.map((doc) => {

            const { sheet, id } = doc

            let yearsToCheck = []
            if (targetYear < 2019) {
                yearsToCheck = [...YEARS]
            } else if (targetYear > 2025) {
                yearsToCheck = [...YEARS].reverse()
            } else {
                const beforeInclusive = YEARS.slice(0, YEARS.indexOf(targetYear.toString()) + 1).reverse()
                const after = YEARS.slice(YEARS.indexOf(targetYear.toString()))
                yearsToCheck = [...beforeInclusive, ...after]

            }

            if (id === "beis-products") {
                return {
                    "beis": setEmissionFactor({ obj: doc, yearsToCheck, sheet })
                }
            }

            return setEmissionFactor({ obj: doc, yearsToCheck, sheet })
        })

        const singleYearSheetsConverted = singleYearSheets
            .map((doc) => {
                const { sheet, id, year, source, ...sections } = doc

                const result = {}

                Object.entries(sections).forEach(([sectionKey, section]) => {
                    Object.entries(section).forEach(([key, value]) => {
                        set(result, [id, sectionKey, key].join("."), value)

                    })
                })

                return result
            })


        const result = merge(...multiYearSheetsConverted, ...singleYearSheetsConverted)

        const bankFactorValues = Object.values(result.banks.CO2E_PER_GBP).map((ef) => ef.value)
        const averageBankFactor = calculateAverageOfArrayValues(bankFactorValues);

        result.banks.CO2E_PER_GBP.average = averageBankFactor

        const pensionFactors = Object.values(
            result.pensions.CO2E_PER_GBP
        ).reduce((acc, provider) => acc.concat(Object.values(provider)), [])
            .map((ef) => ef.value);

        const averagePensionFactor = calculateAverageOfArrayValues(pensionFactors);

        result.pensions.CO2E_PER_GBP.average = averagePensionFactor

        return result
    } catch (e) {
        console.log(e)
    }

}


