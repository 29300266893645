const ORG_PATH = ["organisations", "ORG_ID"];
const SETTINGS_PATH = [...ORG_PATH, "settings"];
const PERIOD_PATH = [...ORG_PATH, "periods", "PERIOD_ID"];
const DETAILS_PATH = [...PERIOD_PATH, "details"];

export const DB_PATHS = Object.freeze({
  organisations: ["organisations"],
  globalEmissionFactors: ["emission-factors-new"],
  addedFactors: ["emission-factors-new", "added"],
  organisation: ORG_PATH,
  periods: [...ORG_PATH, "periods"],
  adminUsers: [...ORG_PATH, "admin-users"],
  user: [...ORG_PATH, "admin-users", "USER_ID"],
  logs: [...ORG_PATH, "logs"],
  settings: SETTINGS_PATH,
  emissionFactors: [...SETTINGS_PATH, "emission-factors"],
  project: [...SETTINGS_PATH, "project"],
  tasks: [...SETTINGS_PATH, "tasks"],
  period: PERIOD_PATH,
  details: DETAILS_PATH,
  coreData: [...DETAILS_PATH, "core-data"],
  siteData: [...DETAILS_PATH, "site-data"],
  sites: [...DETAILS_PATH, "site-data", "sites"],
  employeeData: [...DETAILS_PATH, "employee-data"],
  employees: [...DETAILS_PATH, "employee-data", "employees"],
  volunteerData: [...DETAILS_PATH, "volunteer-data"],
  dentistData: [...DETAILS_PATH, "dentist-data"],
  forecastingData: [...DETAILS_PATH, "forecasting-data"],
  fleetData: [...DETAILS_PATH, "fleet-data"],
  purchaseData: [...DETAILS_PATH, "purchase-data"],
  purchases: [...DETAILS_PATH, "purchase-data", "purchases"],
  supplierData: [...DETAILS_PATH, "supplier-data"],
  suppliers: [...DETAILS_PATH, "supplier-data", "suppliers"],
  supplierSurvey: [...DETAILS_PATH, "supplier-data", "supplier-survey"],
  travelData: [...DETAILS_PATH, "travel-data"],
  investmentData: [...DETAILS_PATH, "investment-data"],
  webData: [...DETAILS_PATH, "web-data"],
  pensionProviders: [...DETAILS_PATH, "investment-data", "pension-providers"],
  investmentProviders: [
    ...DETAILS_PATH,
    "investment-data",
    "investment-providers",
  ],
  materialsData: [...DETAILS_PATH, "materials-data"],
  packagingData: [...DETAILS_PATH, "packaging-data"],
  distributionData: [...DETAILS_PATH, "distribution-data"],
  upstreamDistributionData: [...DETAILS_PATH, "upstream-distribution-data"],
  adData: [...DETAILS_PATH, "ad-data"],
  results: [...PERIOD_PATH, "results"],
  resultLogs: [...PERIOD_PATH, "result-logs"],
  periodSettingsCollection: [...PERIOD_PATH, "settings"],
  periodSettings: [...PERIOD_PATH, "settings", "project"],
  assessmentSurvey: [...DETAILS_PATH, "assessment-survey"],
  assessmentSurveyEntries: [...DETAILS_PATH, "assessment-survey", "entries"],
  selfAssessmentSurvey: [...DETAILS_PATH, "assessment-survey", "entries", "self"],
  globalSurvey: ["global", "esg-survey"],
  globalSurveyVersions: ["global", "esg-survey", "questions"],
});
